<template lang="pug">
include ../../../../configs/template
div.row.pa-6
  div(v-if="checkAccess('experience', 'preVerification', sailorDocument) || checkAccess('experience', 'editStatus', sailorDocument)").col-12
    +select-validation('body.status_document', 'listStatus', 'status', 'nameLang', '["required"]')
  v-checkbox(v-if="body.status_document === STATUSES_DOCUMENT_CONSTANTS.SAILOR.EXPERIENCE.CERTIFICATE.APPROVED" v-model="body.is_confirmed" :label="$t('isConfirmedExperience')").col-3
  div(v-if="checkAccess('experience', 'preVerification', sailorDocument) && statuses.includes(body.status_document)").col-12
    Reject(:statusDocument="body.status_document")
  div.col-12
    v-btn(
      v-if="![STATUSES_DOCUMENT_CONSTANTS.SAILOR.EXPERIENCE.CERTIFICATE.IN_PROCESSING, STATUSES_DOCUMENT_CONSTANTS.SAILOR.EXPERIENCE.CERTIFICATE.INVALID].includes(body.status_document)"
      color="success"
      :loading="buttonLoader"
      @click="checkAccess('experience', 'preVerification', sailorDocument) || checkAccess('experience', 'editStatus', sailorDocument) ? saveNewStatus() : checkSave()"
      ) {{ checkAccess('experience', 'preVerification', sailorDocument) || checkAccess('experience', 'editStatus', sailorDocument) ? $t('save') : $t('setVerify') }}
</template>

<script>
import { checkAccess } from '@/mixins/permissions'
import { mapActions, mapGetters, mapState } from 'vuex'
import { clearBody } from '@/mixins/main'
import { sailorDocumentChangedStatus } from '@/mixins/validationRules'
import { SUCCESS_CODE, STATUSES_DOCUMENT_CONSTANTS } from '@/configs/constants'

export default {
  name: 'SailorExperienceEditStatus',
  props: {
    sailorDocument: { type: Object, default: () => ({}) }
  },
  components: {
    Reject: () => import('./Reject.vue')
  },
  data () {
    return {
      STATUSES_DOCUMENT_CONSTANTS,
      body: {
        status_document: this.sailorDocument.status_document,
        is_confirmed: this.sailorDocument.is_confirmed
      },
      statuses: [STATUSES_DOCUMENT_CONSTANTS.SAILOR.EXPERIENCE.CERTIFICATE.IN_PROCESSING,
        STATUSES_DOCUMENT_CONSTANTS.SAILOR.EXPERIENCE.CERTIFICATE.INVALID],
      buttonLoader: false,
      checkAccess
    }
  },
  computed: {
    ...mapState({
      nameLang: state => (state.main.lang === 'en') ? 'name_eng' : 'name_ukr',
      userId: state => state.main.user.id,
      id: state => state.sailor.sailorId
    }),
    ...mapGetters(['statusChoose']),
    listStatus () {
      let statuses = this.statusChoose('LineInServiceRecord')
      if (checkAccess('admin')) {
        return statuses.concat(this.statusChoose('BackOffice'))
      }
      if (this.userId === 188) {
        return statuses.filter(val => (val.id === STATUSES_DOCUMENT_CONSTANTS.SAILOR.EXPERIENCE.CERTIFICATE.APPROVED) || (this.sailorDocument.status_document === STATUSES_DOCUMENT_CONSTANTS.SAILOR.EXPERIENCE.CERTIFICATE.APPROVED && val.id === STATUSES_DOCUMENT_CONSTANTS.SAILOR.EXPERIENCE.CERTIFICATE.INVALID))
      } else return statuses
    }
  },
  validations () { return sailorDocumentChangedStatus() },
  methods: {
    ...mapActions(['updateExperienceCertificateById']),
    checkSave () {
      this.$swal({
        title: this.$t('warning'),
        text: this.$t('continueVerification'),
        icon: 'info',
        buttons: [this.$t('cancel'), this.$t('setVerify')],
        dangerMode: true
      }).then((confirmation) => {
        if (confirmation) this.saveNewStatus()
      })
    },

    async saveNewStatus () {
      this.buttonLoader = true
      let data = {
        ...this.$route.params, body: { ...clearBody({ ...this.body }) }
      }
      if (!checkAccess('experience', 'preVerification', this.sailorDocument) ||
        !checkAccess('verifierBackOfficeSecond')) {
        data.body.status_document = STATUSES_DOCUMENT_CONSTANTS.SAILOR.EXPERIENCE.CERTIFICATE.VERIFICATION
      }
      const response = await this.updateExperienceCertificateById(data)
      if (SUCCESS_CODE.includes(response.code)) this.$notification.success('infoExperienceDoc')
      this.buttonLoader = false
    }
  }
}

</script>
